var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "bg_gray" }),
      _c("headbox", {
        attrs: {
          title: "快递详情",
          bgColor: "#fff",
          customPath: true,
          path: "pickUp",
          query: { active: "b" },
          isborder: "true"
        }
      }),
      _c("div", { staticClass: "centerbox" }, [
        _c("div", {}, [
          _c("div", { staticClass: "msgbox logtime" }, [
            _c("div", { staticClass: "rel" }, [
              _c("h2", { staticClass: "flex-between h2" }, [
                _c("div", [
                  _vm._v(" 运单号："),
                  _c("b", [_vm._v(_vm._s(_vm.expressNo))])
                ])
              ])
            ]),
            _vm.requestStatus && _vm.datalist.State !== "0"
              ? _c(
                  "div",
                  _vm._l(_vm.datalist.Traces, function(items, index) {
                    return _c("div", { key: index, staticClass: "infolist" }, [
                      _c("div", { staticClass: "flex logexpress" }, [
                        _c("div", { staticClass: "timebox" }, [
                          _vm._v(
                            " " + _vm._s(items["AcceptTime"].split(" ")[0])
                          ),
                          _c("br"),
                          _vm._v(
                            _vm._s(items["AcceptTime"].split(" ")[1]) + " "
                          )
                        ]),
                        _vm._m(0, true),
                        _c("div", { staticClass: "remarkbox" }, [
                          _vm._v(_vm._s(items.AcceptStation))
                        ])
                      ])
                    ])
                  }),
                  0
                )
              : _c(
                  "div",
                  { staticClass: "requestFail" },
                  [
                    _c("van-empty", {
                      staticClass: "custom-image",
                      attrs: {
                        image: require("@/assets/images/noExpress.png"),
                        description: "暂无快递信息"
                      }
                    })
                  ],
                  1
                )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "borderbox" }, [_c("b")])
  }
]
render._withStripped = true

export { render, staticRenderFns }